<template>

<div style="background-color:#989898; height:100vh;">
<div style="position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);">
<center>
<svg
   width="800"
   height="200"
   viewBox="0 0 211.66667 52.916666"
   version="1.1"
   id="svg5"
   sodipodi:docname="Namen.svg"
   inkscape:version="1.2.1 (9c6d41e410, 2022-07-14, custom)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <sodipodi:namedview
     id="namedview7"
     pagecolor="#c8c8c8"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="false"
     inkscape:zoom="0.67526655"
     inkscape:cx="385.03314"
     inkscape:cy="561.25984"
     inkscape:window-width="1920"
     inkscape:window-height="1003"
     inkscape:window-x="0"
     inkscape:window-y="0"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer1" />
  <defs
     id="defs2" />
  <g
     inkscape:label="Ebene 1"
     inkscape:groupmode="layer"
     id="layer1">
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="59.367504"
       y="29.708536"
       id="text236"
       inkscape:label="W"><tspan
         sodipodi:role="line"
         id="tspan234"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="59.367504"
         y="29.708536">W</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="73.923401"
       y="29.652092"
       id="text290"
       inkscape:label="e1"><tspan
         sodipodi:role="line"
         id="tspan288"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="73.923401"
         y="29.652092">e</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="83.658936"
       y="29.708536"
       id="text294"
       inkscape:label="i1"><tspan
         sodipodi:role="line"
         id="tspan292"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="83.658936"
         y="29.708536">i</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="89.567535"
       y="29.708536"
       id="text298"
       inkscape:label="n"><tspan
         sodipodi:role="line"
         id="tspan296"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="89.567535"
         y="29.708536">n</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="100.01427"
       y="29.708536"
       id="text302"
       inkscape:label="z"><tspan
         sodipodi:role="line"
         id="tspan300"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="100.01427"
         y="29.708536">z</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="108.82412"
       y="29.708536"
       id="text306"
       inkscape:label="i2"><tspan
         sodipodi:role="line"
         id="tspan304"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="108.82412"
         y="29.708536">i</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="115.07138"
       y="29.652092"
       id="text310"
       inkscape:label="e2"><tspan
         sodipodi:role="line"
         id="tspan308"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="115.07138"
         y="29.652092">e</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="124.7279"
       y="29.708536"
       id="text314"
       inkscape:label="r"><tspan
         sodipodi:role="line"
         id="tspan312"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="124.7279"
         y="29.708536">r</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="133.08618"
       y="29.708536"
       id="text318"
       inkscape:label="l"><tspan
         sodipodi:role="line"
         id="tspan316"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="133.08618"
         y="29.708536">l</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="146.82071"
       y="29.708536"
       id="text322"
       inkscape:label="I"><tspan
         sodipodi:role="line"
         id="tspan320"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="146.82071"
         y="29.708536">I</tspan></text>
    <text
       xml:space="preserve"
       style="font-size:11.2889px;line-height:1.25;font-family:sans-serif;stroke-width:0.264583"
       x="154.89677"
       y="29.708536"
       id="text326"
       inkscape:label="T"><tspan
         sodipodi:role="line"
         id="tspan324"
         style="font-size:11.2889px;stroke-width:0.264583"
         x="154.89677"
         y="29.708536">T</tspan></text>
  </g>
</svg>
</center>
</div>

</div>
<br><br><br><br>

  <section class="section-three">
    <center><h3>Herzlich Willkommen auf meiner privaten Seite.</h3></center>


  </section>


</template>

<script>
export default {
  name: 'Home',
  components: {


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
<style>
@keyframes fadeIn{
from{
	opacity: 0;
}
to {
	opacity: 1;
}
}
svg {
	animation: fadeIn 2.5s ease-in alternate;
}
</style>
