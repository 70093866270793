<template>
 <footer>
    <div align="center">
      <div class="menu">
      <router-link to="/impressum">Impressum</router-link>
        <router-link to="/GDPR">DSGVO</router-link>
        <router-link to="/contact">Kontakt</router-link>
        <router-link to="/about">Über</router-link>
        </div>
        
    </div>
  </footer>
</template>

<script>


export default {
  name: 'Seitenfooter',
  props: {

  }
  }

</script>
