<template>
<navbar />


  <router-view/>
<Seitenfooter />
</template>


<style>
  @import './assets/css/style.css';
</style>

<script>
// @ is an alias to /srca
import navbar from '@/components/layout/navbar.vue'
import Seitenfooter from '@/components/layout/Seitenfooter.vue'
import store from '@/assets/js/store.js';



export default {
  name: 'Home',
  data: function() {
    return {
      store: store
    }
  },
  components: {
    //HelloWorld,
    Seitenfooter,
    navbar,
  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
}
</script>
