import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: () => import(/* webpackChunkName: "about" */ '../views/gdpr.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/projects.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import(/* webpackChunkName: "about" */ '../views/info.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "about" */ '../views/impressum.vue')
  },
  {
    path: '/visavid',
    name: 'visavid',
    component: () => import(/* webpackChunkName: "about" */ '../views/visavid.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue')
  },
  {
    path: '/social',
    name: 'social',
    component: () => import(/* webpackChunkName: "about" */ '../views/social.vue')
  },
  {
    path: '/script',
    name: 'script',
    component: () => import(/* webpackChunkName: "about" */ '../views/script.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import(/* webpackChunkName: "about" */ '../views/notfound.vue')
  }

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
